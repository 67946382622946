@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.about {
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: rgb(255, 255, 255);
  overflow-wrap: anywhere;
}

/* -----------------------------------------------Reusable Components-------------------------------------------------------- */
.about h2 {
  font-weight: 600;
  z-index: 2;
}

.about h3 {
  font-weight: 600;
  z-index: 2;
}

ion-icon {
  font-size: 300%;
  color: rgb(0, 140, 140);
  background-color: rgb(241, 241, 241);
  padding: 0.93rem;
  border-radius: 200%;
}

/* -------------------------------------------About Us Section--------------------------------------------------------------- */
.noti-first-section::before {
  content: "";
  
  width: 200px;
  height: 200px;
  background-color: #07614a21;
  display: block;
  border-radius: 50%;
  position: absolute;

}

.about .notification{
width: 100%;
margin-bottom: 10rem;
display: flex;
padding: 10rem 1rem;
justify-content: center;
align-items: center;
}
.about .noti-first-section{
 flex: 1;
}

.ambition-second-section{
  display: flex;
  position: relative;
  flex: 1;
 
}

.ambition-second-section img {
  
  border: 6px solid var(--body-color);
  border-radius: 10px;
  
}
.ambition-second-section .img1{
  position: absolute;
  left: 50px;
  bottom: 0;
}
.ambition-second-section .img2{
  position: absolute;
  top: -150px;
  right: -75px;
}
.ambition-second-section .img3{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.about-header {
    background: url('../../resources/images/service-bg\ \(1\).jpg');
    height:50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
}
.about-header::before{
    position: absolute;
    content: '';
    background: #000000b9;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
    z-index: 1;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-title {
  color: white;
}

.about-title h3 {
  color: white;
}
.abouth1 {
  margin-top: 10%;
  font-size: 3.5rem;
  z-index: 2;
}
.abouth3 {
  font-weight: 100;
  font-size: 120%;
}

.section-about-content {
  margin-top: 80px;
  display: flex;
  padding: 2%;
}

.img-container {
  margin-right: 5%;
  display: flex;
  justify-content: center;
}
.about-container img{
  height: 90%;
}

.about-container {
  width: 50%;

}
.about-container h3 {
  
  color: var(--global-color);
}

.about-container h2 {
  font-size: 50px;
  margin-bottom: 2rem;
}
.about-container p{
  font-size: 15px;
  margin-bottom: 2rem;
}

.about-us-features-content h2 {
  font-size: 150%;
}

.about-us-features-container {
  display: flex;
  flex-direction: row;
}

.about-us-features-container :first-child {
  margin-top: 0.6rem;
  margin-right: 0.6rem;
}

.about-us-features-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about-us-features-button p {
  padding: 2%;
  background-color: var(--global-color);
  text-align: center;
  margin-top: 8%;
  color: white;
  width: 25%;
}

.section-about-details {
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin: 5rem;
}

.section-about-details-tabs {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 4.4rem 0;
}
.section-about-details-tabs:nth-child(1) {
  background-color: white;
}
.section-about-details-tabs:nth-child(2) {
  background-color: black;
  color: white;
}

.section-about-details-tabs:nth-child(3) {
  background-color: var(--global-color);
  color: white;
}

.section-about-details-tabs-text {
  margin-left: 1.25rem;
}

.section-about-details-tabs-text p:first-child {
  font-size: 200%;
  font-weight: 600;
}

.section-about-details-tabs-text p:nth-child(2) {
  font-size: 120%;
}

.section-about-newsletter {
  display: flex;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: var(--global-color);
}

.about-newsletter {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.about-newsletter form {
  width: 100%;
  display: flex;
  justify-content: center;
}

.about-newsletter h2 {
  color: white;
  font-size: 2.3rem;
}
.about input[type='email'] {
  padding: 1rem 2rem;
  width: 100%;
  outline: none;
  background-color:rgb(0, 159, 159);
  ;
  border: none;
}

.about input[type='submit'] {
  padding: 1rem 2rem;
  font-size: 0.9rem;
  width: 60%;
  background-color: black;
  color: white;
  border: none;
  margin-top: 1rem;
}

.about input::placeholder {
  color: white;
}

/* ---------------------------------------------Contact section ------------------------------------*/
.section-contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 9rem;
  overflow: hidden;
}

.section-contact-header h2 {
  text-align: center;
  font-size: 2.5rem;
}

.section-contact-header h3 {
  text-align: center;
  text-transform: uppercase;
  color: var(--global-color);
}

.contact-details-container {
  display: flex;
  margin-top: 6rem;
}

.contact-details-tabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  margin: 2rem;
  padding: 5rem;
  position: relative;
  
}

.contact-details-tabs-icon ion-icon {
  background-color: var(--global-color);
  color: white;
  padding: 1.5rem;
  position: absolute;
  top: 0;
  left: 50;
  transform: translate(-50%, -50%);
}

.contact-details-tabs-text p {
  font-size: 1.5rem;
  text-align: center;
}

.contact-details-tabs-text span {
  display: block;
  font-size: 1rem;
}

/* Contact form--------------------- */

.section-contact-form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.contact-form {
  width: 100%;
}
.contact-form-btn{
  background-color: var(--global-color);
  width: 90%;
}

.about form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1rem;
}

.contact-form-input {
  width: 100%;
}

.about .contact-form  input {
  padding: 1rem 2rem;
  margin: 1rem 1rem;
  width: 90%;
  outline: none;
  background-color: rgb(255, 255, 255);
  border: none;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.contact-form form input[type='text'] {
  padding: 1rem 2rem 6rem 2rem;
  width: 90%;
}

.contact-form form input::placeholder {
  color: #555;
}
/* Privacy Policy----------------------------------------- */
.section-policy {
  margin: 8rem;
  text-align: center;
}

/* ----------------------------tESTIMONIAKLS----------------------------------------- */
.testimonials-introduction {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.testimonials-introduction p {
  text-align: center;
}

.customer-testimonials-row {
  display: flex;
  margin-bottom: 5rem;
}

.customer-testimonials-row p {
  text-align: center;
}
.refunds-text {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.customer-testimonials-row p:nth-child(2) {
  font-style: italic;
}
.map-img img{
  object-fit: contain;
  height: 300px;
  
}

.customer-container-testimonial {
  background-color: white;
  margin: 0 2rem;
  padding: 4rem;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1024px) {
    /* -----------------------------About Us page -----------------------------*/
    .about-container:nth-child(2) {
      margin-left: 5rem;
    }
    .ambition-second-section{
      margin-top: 20rem;
    }
    .img-container {
      display: flex;
      justify-content: center;
    }
    .about-us-features-button p {
      padding: 5%;
      width: 50%;
    }
    .about .notification{
      flex-direction: column;
      }
    /* -----------------------------About Us page - about details tab -----------------------------*/
  
    .section-about-details {
      display: flex;
      padding-left: 2%;
      padding-right: 2%;
      margin: 3rem;
    }
  
    .section-about-details-tabs-text p:first-child {
      font-size: 1.5rem;
      font-weight: 600;
    }
  
    .section-about-details-tabs-text p:nth-child(2) {
      font-size: 1.1rem;
    }
    .about-newsletter h2 {
      font-size: 1.8rem;
    }
  
    .about input {
      width: 90%;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    .about input[type='submit'] {
      width: 90%;
    }
    /* ------------------------------------Testimonials-------------------------------------- */
  
    .customer-container-testimonial {
      background-color: white;
      margin: 0 2rem;
      padding: 1rem;
      
    }
  }
  
  @media (max-width: 820px) {
    
    /* -----------------------------About Us page -----------------------------*/
    .section-about-content {
      flex-direction: column;
    }
    .about .notification{
      flex-direction: column;
      }
    
    .about-container h2{
        font-size: 25px;
    }
  
    .section-about-details {
      margin-top: 20rem;
      margin-bottom: 20rem;
    }
    .about-container {
      width: 100%;
      margin-top: 5rem;
      padding: 0;
    }
    .about-container:nth-child(2) {
      margin-left: 0;
    }
  
    .img-container {
      display: flex;
      justify-content: center;
    }
    .about-us-features-button p {
      padding: 3%;
      width: 25%;
    }
    .section-contact-form{
        padding: 0;
    }
    /* -----------------------------About Us page - about details tab -----------------------------*/
  
    .section-about-details {
      display: flex;
      padding-left: 2%;
      padding-right: 2%;
      margin: 3rem;
    }
  
    .section-about-details-tabs-text p:first-child {
      font-size: 1.5rem;
      font-weight: 600;
    }
  
    .section-about-details-tabs-text p:nth-child(2) {
      font-size: 1.1rem;
    }
    /* comtact tab media queriess------------------------------------------- */
    .contact-details-container {
      flex-direction: column;
    }
  
    .contact-details-tabs {
      width: 90%;
    }
  
    .contact-details-tabs-text p {
      font-size: 1.2rem;
      text-align: center;
    }
  
    .contact-details-tabs-text span {
      display: block;
      font-size: 0.8rem;
    }
  
    .contact-details-container {
      align-items: center;
    }
    .about input[type='submit'] {
      width: 90%;
    }
    .about input {
      width: 90%;
      text-align: center;
      margin-bottom: 0.5rem;
    }
  
    .contact-form form input {
      text-align: start;
    }
    .about-newsletter h2 {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 1rem;
    }
    /* ------------------------------------Testimonials-------------------------------------- */
  
    .customer-container-testimonial {
      background-color: white;
      margin: 0 1rem;
      padding: 2rem 0.7rem;
      margin-bottom: 2rem;
    }
  
    .refunds-text {
      font-size: 0.8rem;
    }
    .customer-testimonials-row p:nth-child(2) {
      font-style: italic;
      font-size: 0.9rem;
    }
    .customer-testimonials-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.1rem;
    }
  }
  
  @media (max-width: 430px) {
    body {
      font-size: 15px;
    }
    ion-icon {
      font-size: 200%;
      padding: 0.7rem;
    }
  
    /* -----------------------------About Us page - about details tab -----------------------------*/
  
    .img-container {
      overflow: hidden;
    }
    .section-about-content {
      margin-bottom: 8rem;
    }
  
    .section-about-details {
      flex-direction: column;
    }
  
    .section-about-details-tabs-text p:first-child {
      font-size: 1.5rem;
      font-weight: 600;
    }
  
    .section-about-details-tabs-text p:nth-child(2) {
      font-size: 1.1rem;
    }
  
    .about-us-features-button {
      display: block;
      justify-content: center;
    }
    .about-us-features-button p {
      padding: 5%;
      width: 50%;
      text-align: center;
    }
  
    /* Contact us section-------------------------------------- */
    .section-contact-header h2 {
      text-align: center;
      font-size: 1rem;
    }
  
    .section-contact-header h3 {
      text-align: center;
      text-transform: uppercase;
      color: var(--global-color);
    }
    .abouth1 {
      margin-top: 30%;
      font-size: 1.5rem;
    }
    .abouth3 {
      font-weight: 100;
      font-size: 1.2rem;
    }
  
    .section-about-newsletter {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .about-newsletter {
      width: 90%;
    }
    .about-newsletter h2 {
      font-size: 0.8rem;
      text-align: center;
      margin-bottom: 1rem;
    }
    .about input{
      width: 90%;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    .about input[type='submit'] {
      width: 90%;
    }
  
    .contact-form form input{
      width: 90%;
      text-align: center;
    }
  
    .input[type='text'] {
      width: 90%;
    }
  
    /* comtact tab media queriess------------------------------------------- */
    .contact-details-container {
      flex-direction: column;
    }
  
    .contact-details-tabs {
      width: 90%;
    }
  
    .contact-details-tabs-text p {
      font-size: 1.2rem;
      text-align: center;
    }
  
    .contact-details-tabs-text span {
      display: block;
      font-size: 0.8rem;
    }
  
    .contact-details-container {
      align-items: center;
    }
  
    /* Privacy Policy----------------------------------------- */
    .section-policy {
      margin: 2rem;
    }
  }
  